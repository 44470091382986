<template>
  <div>
    <h5 class="text-md-left text-center">Datos del Asegurado</h5>
    <form id="formulario">
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="form-group">
            <label>Nombre</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle></svg
              ><input
                v-model="$v.form.name.$model"
                id="name"
                type="text"
                placeholder="Ingrese nombre :"
                class="form-control pl-5"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.name.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Apellido Paterno</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user-check"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline></svg
              ><input
                v-model="$v.form.lastNameFa.$model"
                name="lastNameFa"
                id="last"
                type="text"
                placeholder="Ingrese Apellido Paterno :"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.lastNameFa.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Apellido Materno</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user-check"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline></svg
              ><input
                v-model="$v.form.lastNameMa.$model"
                name="lastNameMa"
                id="last"
                type="text"
                placeholder="Ingrese Apellido Materno :"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.lastNameMa.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Email</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline></svg
              ><input
                v-model="$v.form.email.$model"
                name="email"
                id="email"
                type="email"
                placeholder="Tu email :"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.email.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Tipo de Contratante</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-bookmark"
              >
                <path
                  d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                ></path></svg
              ><input
                v-model="$v.form.contractor.$model"
                name="contractor"
                id="contractor"
                type="text"
                placeholder="Tipo de Contratante :"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.contractor.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Tipo de Vivienda</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-bookmark"
              >
                <path
                  d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                ></path></svg
              ><input
                v-model="$v.form.livingPlace.$model"
                name="livingPlace"
                id="livingPlace"
                type="text"
                placeholder="Tipo de Vivienda :"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.livingPlace.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="h6">Años de Construccion</label>
            <select
              class="form-control form-select"
              aria-label="Default select example"
            >
              <option selected>1</option>
              <option selected>2</option>
              <option selected>3</option>
              <option selected>4</option>
            </select>
          </div>
        </div>
      </div>
    </form>
    <h5 class="mt-5">Información de contacto</h5>
    <div class="row">
      <div class="col-md-6 mt-2 pt-2">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Teléfono No. :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-phone"
                  >
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.phone.$model"
                    name="phone"
                    id="phone"
                    type="number"
                    placeholder="Phone :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.phone.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Calle :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                      d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.addressStreet.$model"
                    name="addressStreet"
                    id="addressStreet"
                    type="text"
                    placeholder="Ingrese dirección :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressStreet.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Num Exterior :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                      d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.addressNE.$model"
                    name="addressNE"
                    id="addressNE"
                    type="text"
                    placeholder="Ingrese dirección :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressNE.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Num interior :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                      d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.addressNI.$model"
                    name="addressNI"
                    id="addressNI"
                    type="text"
                    placeholder="Ingrese dirección :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressNI.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>País :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                      d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.country.$model"
                    name="country"
                    id="country"
                    type="text"
                    placeholder="Ingrese su pais :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.country.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-6 pt-2">
        <form>
          <div class="row mt-2">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Colonia :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-key"
                  >
                    <path
                      d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.suburb.$model"
                    name="suburb"
                    id="suburb"
                    type="text"
                    placeholder="Colonia :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.suburb.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>C.P :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-key"
                  >
                    <path
                      d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.CP.$model"
                    name="CP"
                    id="CP"
                    type="text"
                    placeholder="Colonia :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.CP.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Municipio :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-key"
                  >
                    <path
                      d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.municipality.$model"
                    name="municipality"
                    id="municipality"
                    type="text"
                    placeholder="Ingrese municipio :"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.municipality.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Estado :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-key"
                  >
                    <path
                      d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.state.$model"
                    name="state"
                    id="state"
                    type="text"
                    placeholder="Ingrese Estado :"
                    class="form-control"
                    required=""
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.state.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>RFC :</label>
                <div class="position-relative">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="fea icon-sm icons feather feather-key"
                  >
                    <path
                      d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                    ></path></svg
                  > -->
                  <input
                    v-model="$v.form.RFC.$model"
                    name="RFC"
                    id="RFC"
                    type="text"
                    placeholder="Ingrese su RFC :"
                    class="form-control"
                    required=""
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.RFC.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="
                pt-4
                d-flex
                text-center
                justify-content-between
                pb-4
                col-lg-12
              "
            >
              <h6 class="mb-0 pr-3 mt-2">
                ¿Solicita factura para al finalizar el proceso?
              </h6>
              <div class="mt-1 custom-control custom-switch">
                <input
                  v-model="$v.form.switch1.$model"
                  type="checkbox"
                  id="customSwitch1"
                  class="custom-control-input"
                /><label
                  for="customSwitch1"
                  class="custom-control-label mt-2"
                ></label>
              </div>
            </div> -->
          </div>
        </form>
      </div>
    </div>
    <div>
      <div class="rounded shadow mt-4">
        <div class="p-4 border-bottom">
          <h5 class="mb-0">Información complementaria</h5>
        </div>
        <div class="p-4">
          <div class="d-flex justify-content-between pb-4">
            <h6 class="mb-0">¿El cliente actua por su propia cuenta?</h6>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                id="customSwitch2"
                class="custom-control-input"
              /><label for="customSwitch2" class="custom-control-label"></label>
            </div>
          </div>
          <div class="media d-flex justify-content-between py-4 border-top">
            <h6 class="mb-0">
              ¿Cuanto estima gastar anualmente en seguros con AIG?
            </h6>
            <div class="row col-sm-2">
              <div class="form-group">
                <div class="position-relative">
                  <input
                    v-model="$v.form.avg.$model"
                    name="avg"
                    id="avg"
                    type="number"
                    class="border-light form-control text-center"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="media d-flex justify-content-between py-4 border-top">
            <h6 class="mb-0 text-justify" style="width: 600px">
              Manifiesto bajo protesta de decir verdad, que he verificado los
              datos y la informacion aquí proporcionada, mismos que fueron
              obtenidos mediante entrevista personal al cliente
            </h6>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                id="customSwitch3"
                class="custom-control-input"
              /><label for="customSwitch3" class="custom-control-label"></label>
            </div>
          </div>
        </div>
      </div>
      <div class="rounded shadow mt-4">
        <div class="p-4">
          <h6 class="mb-0">
            Asegurese de ingresar la información correcta para la emisión del
            seguro
          </h6>
          <div class="mt-4">
            <button
              v-bind:disabled="disabled"
              @click="addInsuredDoc()"
              class="btn btn-sm btn-primary"
            >
              <span
                v-if="disabled === true"
                class="mr-3 spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>

              Continuar
            </button>

            <b-toast id="my-toast-insured" variant="primary" solid>
              <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                  <small class="text-muted font-weight-bold mr-2"></small>
                </div>
              </template>
              <div class="container text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-check-circle mt-5"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
                <h4 class="mb-5 mt-3 font-weight-light "> Asegurado Guardado <br> Exitosamente ! </h4>
              </div>
            </b-toast>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";
import { firestore } from "@/services/firebase.service";
const isTrue = (value) => value === true;

export default {
  mixins: [validationMixin],
  data() {
    return {
      disabled: false,

      form: {
        name: "",
        lastNameFa: "",
        lastNameMa: "",
        email: "",
        contractor: "",
        livingPlace: "",
        phone: "",
        addressStreet: "",
        addressNE: "",
        addressNI: "",
        country: "",
        suburb: "",
        CP: "",
        municipality: "",
        state: "",
        RFC: "",
        avg: "",
        switch1: false,
        switch2: false,
        switch3: false,
      },
    };
  },

  validations: {
    form: {
      name: { required },
      lastNameFa: { required },
      lastNameMa: { required },
      email: { required, email },
      contractor: { required },
      livingPlace: { required },
      phone: { required, numeric },
      addressStreet: { required },
      addressNE: { required },
      addressNI: { required },
      country: { required },
      suburb: { required },
      CP: { required, numeric },
      municipality: { required },
      state: { required },
      RFC: { required },
      avg: { required, numeric },
    },
  },

  methods: {
    nextStep() {
      this.$router.push({
        name: "Insured",
      });
    },

    async addInsuredDoc() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      try {
        this.disabled = !this.disabled;
        await firestore.collection("insured").add({
          name: this.form.name,
          lastNameFa: this.form.lastNameFa,
          lastNameMa: this.form.lastNameMa,
          email: this.form.email,
          contractor: this.form.contractor,
          livingPlace: this.form.livingPlace,
          phone: this.form.phone,
          addressStreet: this.form.addressStreet,
          addressNE: this.form.addressNE,
          addressNI: this.form.addressNI,
          country: this.form.country,
          suburb: this.form.suburb,
          CP: this.form.CP,
          municipality: this.form.municipality,
          state: this.form.state,
          RFC: this.form.RFC,
          avg: this.form.avg,
        });
        this.$bvToast.show("my-toast-insured");
      } catch (e) {
        console.log("no se pudo ejecutar la accion");
      }
      this.disabled = !this.disabled;
    },
  },
};
</script>
